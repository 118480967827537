.custom-dropdown-menu {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 150px;
    padding: 0;
    margin: 5px 0;
    z-index: 1000;
  }
  
  .custom-dropdown-menu li {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .custom-dropdown-menu li:hover {
    background-color: #f1f1f1;
  }

  .dropdown-button {
    background-color: transparent;  /* Transparan arka plan */
    border: 1px solid #ccc;         /* İsteğe bağlı: sınır çizgisi ekleyebilirsiniz */
    padding: 10px 15px;             /* İç boşluk */
    border-radius: 5px;             /* Köşe yuvarlaklığı */
    cursor: pointer;                /* Fare imleci */
    font-size: 12px;                /* Yazı boyutu */
    display: flex;
    align-items: center;
    gap: 5px;                       /* İkon ile metin arasındaki boşluk */
    transition: background-color 0.2s ease;  /* Hover efekti için geçiş efekti */
  }
  
  .dropdown-button:hover {
    background-color: rgba(0, 0, 0, 0.1);  /* Hover efekti için hafif bir transparan arka plan */
  }

@media (max-width: 768px) {
  .custom-dropdown-menu {
    width: 100%;
    left: 0;
    transform: none;
  }

  .dropdown-button {
    width: 100%;
    justify-content: center;
  }
}

