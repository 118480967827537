.card-img-top-wrapper {
    width: 100%;
    height: 200px; /* Set the desired height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card-img-top {
    width: 100%;
    height: auto;
    object-fit: cover; /* Crop the image */
  }

.product-img-wrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-img {
  width: 100%;
  height: auto;
  object-fit: cover; /* Crop the image */
}

.no-image {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  color: #666;
  font-size: 0.875rem;
  text-align: center;
}

.ingredients {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

.ingredient-tag {
  background-color: #e0e0e0;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 0.875rem;
}

.menu-item:hover {
  background-color: #f8f9fa;
}