
body {
  margin: 0;
  font-family: 'Jost', sans-serif!important;
  font-size: 14px!important;
  line-height: 1.5715!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Buttons */
.btn.app--button {
  padding: 10px 23px;
  font-weight: 600;
  max-height: 40px;
  line-height: normal;
  outline: none;
  font-size: 14px;
  border: none;
}

.btn.app--button_green {
  background-color: #28a745;
  color: #fff; 
}

.btn.app--button_green:hover {
  background-color: #218838;
}

.btn.app--button_blue {
  background-color: #007bff;
  color: #fff;
}

.btn.app--button_blue:hover {
  background-color: #0069d9;
}

.btn.app--button_red {
  background-color: #dc3545;
  color: #fff;
}

.btn.app--button_login {
  background-color: #d42528;
  color: #fff;
  border: none;
}


