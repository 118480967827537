.sidebar-desktop {
    width: 250px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #343a40;
    color: white;
    overflow-y: auto;
  }
  
  .sidebar-desktop a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar-desktop a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  