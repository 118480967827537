.layout-content {
    margin-left: 250px;
    transition: margin-left 0.3s ease-in-out;
  }
  
  @media (max-width: 768px) {
    .layout-content {
      margin-left: 0; /* No margin when sidebar is closed */
    }
  }
  